@media screen and (max-width: 420px) {
  .App {
    width: 90%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 420px) {
  .App {
    width: 80%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 576px) {
  .App {
    width: 75%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1000px) {
  .App {
    width: 70%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1200px) {
  .App {
    width: 50%;
    margin: 0 auto;
  }
}
