.filter {
    position: fixed;
    bottom: 5rem;
    right: 3rem;
    color: #E0A6CA;
    background-color: #786b6d;
    border-radius: 1rem;
    padding: 0.2rem;
    cursor: pointer;
}

.bx-slg {
    font-size:5rem;
}