.word-item {
  height: 10rem;
  color: #ffffff;
  font-size: 2rem;
  font-weight: bold;
  border-radius: 1.6rem;
  background-color: #6c6062;

  display: grid;
  grid-template-columns: 0.5fr 1.2fr 1.8fr repeat(3, 1fr);
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  padding: 2rem 3rem;
  margin-bottom: 3rem;
}

.dark-star,
.light-star,
.word-text,
.type,
.play-audio,
.search,
.level {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dark-star {
  cursor: pointer;
  color: #DAD8D7;
}

.light-star {
  cursor: pointer;
  color: yellow;
}

.type {
  font-size: 1.4rem;
  word-break: break-all;
}
.play-audio {
  cursor: pointer;
  color: aquamarine;
}

.search a {
  color: aquamarine;
}

.level span {
  padding: 0 0.4rem;
  background-color: #e74897;
  font-size: 1.4rem;
  font-weight: bolder;
  border-radius: 0.5rem;
}

@media screen and (max-width: 420px) {
  .word-item {
    grid-template-columns: 0.2fr 3fr 0.7fr repeat(2, 0.3fr) 0.5pr;
    padding: 1rem;
  }
  .word-text {
    font-size: 1.5rem;
  }
}
