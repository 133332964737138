.header {
  display: flex;
  align-items: flex-end;
  margin: 1rem 0 3rem;
}

.logo {
  height: calc(10rem + 1vmin);
  /* pointer-events: none; */
}
.title {
  font-size: calc(2rem + 1vmin);
  color: white;
  padding-left:calc(5rem + 5vmin);
}

@media (prefers-reduced-motion: no-preference) {
  .logo {
    /* use the boxicons animation */
    animation:tada 1.5s ease

  }
}

