footer {
  height: 5rem;
  line-height: 5rem;
  margin: 8rem 0 2rem;
  font-size: calc(1rem + 2vmin);
  font-weight: bolder;
  color: #6C6062;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1rem;
  border-radius: 0.5rem;
}
