.remove-star {
  display: flex;
  justify-content: center;
  align-items: center;
}
.remove-star {
  cursor: pointer;
  color: #DAD8D7;
}
  

  