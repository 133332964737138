.page-tip {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60vh;
}

.page-title,.page-text {
    color: #FF5733;
    text-align: center;
    height: 10rem;
    line-height: 10rem;
}
.page-title {
    margin-bottom: 3rem;
    font-size: 5rem;
}

.page-text {
    font-size: 2.5rem;
}