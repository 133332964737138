.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}

.item {
  color: #ffffff;
  font-size: 1.8rem;
  font-weight: bold;
  border-radius: 1.6rem;
  background-color: #6c6062;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 5rem 0 2.5rem;
}

.word-img {
  width: calc(10rem - 2vmin);
}
/* use the boxicons animation */
.word-img:hover{animation:tada 1.5s ease }

@media screen and (max-width: 420px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 420px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 576px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1000px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1200px) {
  .grid {
    grid-template-columns: repeat(4, 1fr);
  }
}